<div class="modal-header">
  <button
    type="button"
    class="close btn"
    aria-label="Close"
    [disabled]="loading"
    (click)="activeModal.close(false)"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 fs-3 fw-bold text-center mt-2">Start a new order?</div>
      <div class="col-12 fs-6 fw-500 text-center mt-2 mb-4">
        Your bad contains items from another restaurant. Empty your bad to start
        a new order.
      </div>

      <div
        class="col-12 d-inline-flex align-items-center justify-content-center"
      >
        <button
          type="button"
          class="btn btn-danger w-50"
          [disabled]="loading"
          (click)="emptyBag()"
        >
          <span *ngIf="!loading; else loadingTemplate" class="user-select-none"
            >Empty bag
          </span>
        </button>
      </div>
    </div>
  </div>
</div>
<ng-template #loadingTemplate>
  <span
    class="spinner-border spinner-border-sm"
    role="status"
    aria-hidden="true"
  ></span>
</ng-template>
