<div class="terms">
  <div class="container">
    <div class="row mt mt-3">
      <div class="col-12 text-center h3">TERMS OF USE</div>
      <div class="col-12 text-center text-secondary">Last updatedJanuary 01, 2020</div>
    </div>
    <div class="row">
      <div class="col-12 h6 mt-4">AGREEMENT TO TERMS</div>
      <div class="col-12">These Terms of Use constitute a legally binding agreement made between you, whether personally
        or on behalf of an entity (“you”) and Universal Processing LLC("<b>Company</b>", “<b>we</b>”, “<b>us</b>”, or
        “<b>our</b>”), concerning your access to and use of the <a href="http://uorder.io/">http://uorder.io/</a>
        website as well as any other media form, media channel, mobile website or mobile application related, linked, or
        otherwise connected thereto (collectively, the “Site”). The Site provides an online marketplace for the
        following goods, products, and/or services). In order to help make the Site a secure environment for the
        purchase and sale of Marketplace Offerings, all users are required to accept and comply with these Terms of Use.
        You agree that by accessing the Site and/or the Marketplace Offerings, you have read, understood, and agree to
        be bound by all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE
        EXPRESSLY PROHIBITED FROM USING THE SITE AND/OR THE MARKETPLACE OFFERINGS AND YOU MUST DISCONTINUE USE
        IMMEDIATELY.</div>
      <div class="col-12 h6 mt-4">INTELLECTUAL PROPERTY RIGHTS</div>
      <div class="col-12 ">Unless otherwise indicated, the Site and the Marketplace Offerings are our proprietary
        property and all source code, databases, functionality, software, website designs, audio, video, text,
        photographs, and graphics on the Site (collectively, the “Content”) and the trademarks, service marks, and logos
        contained therein (the “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright
        and trademark laws and various other intellectual property rights and unfair competition laws of the United
        States, international copyright laws, and international conventions. The Content and the Marks are provided on
        the Site “AS IS” for your information and personal use only. Except as expressly provided in these Terms of Use,
        no part of the Site or the Marketplace Offerings and no Content or Marks may be copied, reproduced, aggregated,
        republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold,
        licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written
        permission.</div>
      <div class="col-12">Provided that you are eligible to use the Site, you are granted a limited license to access
        and use the Site and to download or print a copy of any portion of the Content to which you have properly gained
        access solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in and
        to the Site, the Content and the Marks.</div>
      <div class="col-12 h6 mt-4">USER REGISTRATION</div>
      <div class="col-12">You may be required to register with the Site in order to access the Marketplace Offerings.
        You agree to keep your password confidential and will be responsible for all use of your account and password.
        We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole
        discretion, that such username is inappropriate, obscene, or otherwise objectionable.</div>
      <div class="col-12 h6 mt-4">MARKETPLACE OFFERINGS</div>
      <div class="col-12">
        We make every effort to display as accurately as possible the colors, features, specifications, and details of
        the Marketplace Offerings available on the Site. However, we do not guarantee that the colors, features,
        specifications, and details of the Marketplace Offerings will be accurate, complete, reliable, current, or free
        of other errors, and your electronic display may not accurately reflect the actual colors and details of the
        products. All Marketplace Offerings are subject to availability, and we cannot guarantee that Marketplace
        Offerings will be in stock. Certain Marketplace Offerings may be available exclusively online through the Site.
        Such Marketplace Offerings may have limited quantities and are subject to return or exchange only according to
        our Return Policy.
      </div>
      <div class="col-12">We reserve the right to limit the quantities of the Marketplace Offerings offered or available
        on the Site. All descriptions or pricing of the Marketplace Offerings are subject to change at any time without
        notice, at our sole discretion. We reserve the right to discontinue any Marketplace Offerings at any time for
        any reason. We do not warrant that the quality of any of the Marketplace Offerings purchased by you will meet
        your expectations or that any errors in the Site will be corrected.
      </div>
      <div class="col-12 h6 mt-4">PURCHASES AND PAYMENT
      </div>
      <div class="col-12">
        We accept the following forms of payment:
        <ul class="ps-4">
          <li>- Visa</li>
          <li>- Mastercard</li>
          <li>- American Express</li>
          <li>- Discover</li>
        </ul>
      </div>
      <div class="col-12">You agree to provide current, complete, and accurate purchase and account information for all
        purchases of the Marketplace Offerings made via the Site. You further agree to promptly update account and
        payment information, including email address, payment method, and payment card expiration date, so that we can
        complete your transactions and contact you as needed. Sales tax will be added to the price of purchases as
        deemed required by us. We may change prices at any time. All payments shall be inU.S. dollars.
      </div>
      <div class="col-12">You agree to pay all charges at the prices then in effect for your purchases and any
        applicable shipping fees, and you authorize us to charge your biaosugarsen payment provider for any such amounts
        upon placing your order.We reserve the right to correct any errors or mistakes in pricing, even if we have
        already requested or received payment.
      </div>
      <div class="col-12">We reserve the right to refuse any order placed through the Site. We may, in our sole
        discretion, limit or cancel quantities purchased per person, per household, or per order. These restrictions may
        include orders placed by or under the same customer account, the same payment method, and/or orders that use the
        same billing or shipping address. We reserve the right to limit or prohibit orders that, in our sole judgment,
        appear to be placed by dealers, resellers, or distributors.
      </div>
      <div class="col-12 h6 mt-4">RETURN POLICY
      </div>
      <div class="col-12">
        All sales are final and no refund will be issued.
      </div>
      <div class="col-12 h6 mt-4">SITE MANAGEMENT
      </div>
      <div class="col-12">
        We reserve the right, but not the obligation, to: (1) monitor the Site for violations of these Terms of Use; (2)
        take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of
        Use, including without limitation, reporting such user to law enforcement authorities; (3) in our sole
        discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the
        extent technologically feasible) any of your Contributions or any portion thereof; (4) in our sole discretion
        and without limitation, notice, or liability, to remove from the Site or otherwise disable all files and content
        that are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Site in a
        manner designed to protect our rights and property and to facilitate the proper functioning of the Site and the
        Marketplace Offerings.
      </div>
      <div class="col-12 h6 mt-4">PRIVACY POLICY
      </div>
      <div class="col-12">
        We care about data privacy and security. By using the Site or the Marketplace Offerings, you agree to be bound
        by our Privacy Policy posted on the Site, which is incorporated into these Terms of Use. Please be advised the
        Site and the Marketplace Offerings are hosted in theUnited States. If you access the Site or the Marketplace
        Offerings from any other region of the world with laws or other requirements governing personal data collection,
        use, or disclosure that differ from applicable laws in theUnited States, then through your continued use of the
        Site, you are transferring your data to theUnited States, and you agree to have your data transferred to and
        processed in theUnited States.
      </div>
      <div class="col-12 h6 mt-4">TERM AND TERMINATION
      </div>
      <div class="col-12">
        These Terms of Use shall remain in full force and effect while you use the Site. WITHOUT LIMITING ANY OTHER
        PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
        LIABILITY, DENY ACCESS TO AND USE OF THE SITE AND THE MARKETPLACE OFFERINGS (INCLUDING BLOCKING CERTAIN IP
        ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
        REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE
        MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE AND THE MARKETPLACE OFFERINGS OR DELETEYOUR ACCOUNT AND ANY
        CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
      </div>
      <div class="col-12">If we terminate or suspend your account for any reason, you are prohibited from registering
        and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you
        may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve
        the right to take appropriate legal action, including without limitation pursuing civil, criminal, and
        injunctive redress.
      </div>
      <div class="col-12 h6 mt-4">GOVERNING LAW
      </div>
      <div class="col-12">
        These Terms of Use and your use of the Site and the Marketplace Offerings are governed by and construed in
        accordance with the laws of the State ofNew Yorkapplicable to agreements made and to be entirely performed
        within the State ofNew York, without regard to its conflict of law principles.
      </div>
      <div class="col-12 h6 mt-4">DISPUTE RESOLUTION
      </div>
      <div class="col-12">
        <b>Restrictions
        </b>
      </div>
      <div class="col-12">The Parties agree that any arbitration shall be limited to the Dispute between the Parties
        individually. To the full extent permitted by law, (a) no arbitration shall be joined with any other proceeding;
        (b) there is no right or authority for any Dispute to be arbitrated on a class-action basis or to utilize class
        action procedures; and (c) there is no right or authority for any Dispute to be brought in a purported
        representative capacity on behalf of the general public or any other persons.
      </div>
      <div class="col-12">
        <strong>Exceptions to Informal Negotiations and Arbitration
        </strong>
      </div>
      <div class="col-12">The Parties agree that the following Disputes are not subject to the above provisions
        concerning informal negotiations and binding arbitration: (a) any Disputes seeking to enforce or protect, or
        concerning the validity of, any of the intellectual property rights of a Party; (b) any Dispute related to, or
        arising from, allegations of theft, piracy, invasion of privacy, or unauthorized use; and (c) any claim for
        injunctive relief. If this provision is found to be illegal or unenforceable, then neither Party will elect to
        arbitrate any Dispute falling within that portion of this provision found to be illegal or unenforceable and
        such Dispute shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction
        above, and the Parties agree to submit to the personal jurisdiction of that court.
      </div>
      <div class="col-12 h6 mt-4">CORRECTIONS</div>
      <div class="col-12">There may be information on the Site that contains typographical errors, inaccuracies, or
        omissions that may relate to the Marketplace Offerings, including descriptions, pricing, availability, and
        various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change
        or update the information on the Site at any time, without prior notice.
      </div>
      <div class="col-12 h6 mt-4">DISCLAIMER</div>
      <div class="col-12">THE SITE AND THE MARKETPLACE OFFERINGS ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
        AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY
        LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND THE MARKETPLACE OFFERINGS
        AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
        COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO
        LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL
        INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, (3) ANY
        UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
        INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE OR THE
        MARKETPLACE OFFERINGS, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH
        THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR
        DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE
        AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR
        SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR
        MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
        RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS
        WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
        JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
      </div>
      <div class="col-12 h6 mt-4">INDEMNIFICATION</div>
      <div class="col-12">You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates,
        and all of our respective officers, agents, partners, and employees, from and against any loss, damage,
        liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to
        or arising out of:(1) use of the Marketplace Offerings; (2) breach of these Terms of Use; (3) any breach of your
        representations and warranties set forth in these Terms of Use; (4) your violation of the rights of a third
        party, including but not limited to intellectual property rights; or (5) any overt harmful act toward any other
        user of the Site or the Marketplace Offerings with whom you connected via the Site. Notwithstanding the
        foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for
        which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such
        claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject
        to this indemnification upon becoming aware of it.
      </div>
      <div class="col-12 h6 mt-4">USER DATA
      </div>
      <div class="col-12">We will maintain certain data that you transmit to the Site for the purpose of managing the
        performance of the Marketplace Offerings, as well as data relating to your use of the Marketplace Offerings.
        Although we perform regular routine backups of data, you are solely responsible for all data that you transmit
        or that relates to any activity you have undertaken using the Marketplace Offerings. You agree that we shall
        have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action
        against us arising from any such loss or corruption of such data.
      </div>
      <div class="col-12 h6 mt-4">MISCELLANEOUS</div>
      <div class="col-12">These Terms of Use and any policies or operating rules posted by us on the Site or in respect
        to the Marketplace Offerings constitute the entire agreement and understanding between you and us. Our failure
        to exercise or enforce any right or provision of these Terms of Use shall not operate as a waiver of such right
        or provision. These Terms of Use operate to the fullest extent permissible by law. We may assign any or all of
        our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage,
        delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a
        provision of these Terms of Use is determined to be unlawful, void, or unenforceable, that provision or part of
        the provision is deemed severable from these Terms of Use and does not affect the validity and enforceability of
        any remaining provisions. There is no joint venture, partnership, employment or agency relationship created
        between you and us as a result of these Terms of Use or use of the Marketplace Offerings. You agree that these
        Terms of Use will not be construed against us by virtue of having drafted them. You hereby waive any and all
        defenses you may have based on the electronic form of these Terms of Use and the lack of signing by the parties
        hereto to execute these Terms of Use.
      </div>
      <div class="col-12 h6 mt-4">CONTACT US
      </div>
      <div class="col-12">In order to resolve a complaint regarding the Site or the Marketplace Offerings or to receive
        further information regarding use of the Site or the Marketplace Offerings, please contact us at:
      </div>
      <div class="col-12">
        <ul>
          <li><b>Universal Processing LLC
            </b></li>
          <li><b>60 W 38th St floor 2
            </b></li>
          <li><b>New York,NY 10018
            </b></li>
          <li><b>United States
            </b></li>
          <li><b>Phone: 888-885-8840
            </b></li>
          <li><b>userve@uprocessing.com
            </b></li>
        </ul>
      </div>
    </div>
  </div>
</div>
