<div class="container-fluid four-zero-four">
  <div class="row">
    <div class="col-12">
      <h1>THE PAGE YOU'RE LOOKING FOR MUST BE HERE SOMEWHERE...
      </h1>
    </div>
    <div class="col-12">
      <h6>error code: 404</h6>
    </div>
  </div>
</div>
