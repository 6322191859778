<div class="privacy">
  <div class="container">
    <div class="row mt mt-3">
      <div class="col-12 text-center h3">Privacy</div>
      <div class="col-12 text-center text-secondary">Your privacy is critically important to us.
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12"><b>Universal Processing LLC is located at:</b>
        <ul class="ps-4">
          <li>Universal Processing LLC
          </li>
          <li>60 W 38th St floor 2
          </li>
          <li>New York, United States
          </li>
          <li>888-885-8840
          </li>
        </ul>
      </div>

      <div class="col-12 mt-3">It is Universal Processing LLC's policy to respect your privacy regarding any information
        we may collect while operating our website. This Privacy Policy applies to demo.userveonline.com (hereinafter,
        "us", "we", or "demo.userveonline.com"). We respect your privacy and are committed to protecting personally
        identifiable information you may provide us through the Website. We have adopted this privacy policy ("Privacy
        Policy") to explain what information may be collected on our Website, how we use this information, and under
        what circumstances we may disclose the information to third parties. This Privacy Policy applies only to
        information we collect through the Website and does not apply to our collection of information from other
        sources.
      </div>
      <div class="col-12 mt-2">This Privacy Policy, together with the Terms and conditions posted on our Website, set
        forth
        the general rules and policies governing your use of our Website. Depending on your activities when visiting our
        Website, you may be required to agree to additional terms and conditions.
      </div>

      <div class="col-12 h6 mt-3">- Website Visitors
      </div>
      <div class="col-12">Like most website operators, Universal Processing LLC collects non-personally-identifying
        information of the sort that web browsers and servers typically make available, such as the browser type,
        language preference, referring site, and the date and time of each visitor request. Universal Processing LLC's
        purpose in collecting non-personally identifying information is to better understand how Universal Processing
        LLC's visitors use its website. From time to time, Universal Processing LLC may release
        non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of
        its website.
      </div>
      <div class="col-12">Universal Processing LLC also collects potentially personally-identifying information like
        Internet Protocol (IP) addresses for logged in users and for users leaving comments on
        <a href="http://demo.userveonline.com">http://demo.userveonline.com</a>
        blog posts. Universal Processing LLC only discloses logged in user and commenter IP
        addresses under the same circumstances that it uses and discloses personally-identifying information as
        described below.
      </div>

      <div class="col-12 h6 mt-3">- Gathering of Personally-Identifying Information
      </div>
      <div class="col-12">Certain visitors to Universal Processing LLC's websites biaosugarose to interact with
        Universal Processing LLC in ways that require Universal Processing LLC to gather personally-identifying
        information. The amount and type of information that Universal Processing LLC gathers depends on the nature of
        the interaction. For example, we ask visitors who sign up for a blog at
        <a href="http://demo.userveonline.com">http://demo.userveonline.com</a>
        to provide a username and email address.
      </div>

      <div class="col-12 h6 mt-3">- Security
      </div>
      <div class="col-12">The security of your Personal Information is important to us, but remember that no method of
        transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use
        commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
      </div>

      <div class="col-12 h6 mt-3">- Advertisements
      </div>
      <div class="col-12">Ads appearing on our website may be delivered to users by advertising partners, who may set
        cookies. These cookies allow the ad server to recognize your computer each time they send you an online
        advertisement to compile information about you or others who use your computer. This information allows ad
        networks to, among other things, deliver targeted advertisements that they believe will be of most interest to
        you. This Privacy Policy covers the use of cookies by Universal Processing LLC and does not cover the use of
        cookies by any advertisers.
      </div>

      <div class="col-12 h6 mt-3">- Protection of Certain Personally-Identifying Information
      </div>
      <div class="col-12">Universal Processing LLC discloses potentially personally-identifying and
        personally-identifying information only to those of its employees, contractors and affiliated organizations that
        (i) need to know that information in order to process it on Universal Processing LLC's behalf or to provide
        services available at Universal Processing LLC's website, and (ii) that have agreed not to disclose it to
        others. Some of those employees, contractors and affiliated organizations may be located outside of your home
        country; by using Universal Processing LLC's website, you consent to the transfer of such information to them.
        Universal Processing LLC will not rent or sell potentially personally-identifying and personally-identifying
        information to anyone. Other than to its employees, contractors and affiliated organizations, as described
        above, Universal Processing LLC discloses potentially personally-identifying and personally-identifying
        information only in response to a subpoena, court order or other governmental request, or when Universal
        Processing LLC believes in good faith that disclosure is reasonably necessary to protect the property or rights
        of Universal Processing LLC, third parties or the public at large.
      </div>
      <div class="col-12">If you are a registered user of
        <a href="http://demo.userveonline.com">http://demo.userveonline.com</a>
        and have supplied your email address, Universal Processing LLC may occasionally send you an email to tell you
        about new features, solicit your feedback, or just keep you up to date with what's going on with Universal
        Processing LLC and our products. We primarily use our blog to communicate this type of information, so we expect
        to keep this type of email to a minimum. If you send us a request (for example via a support email or via one of
        our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your
        request or to help us support other users. Universal Processing LLC takes all measures reasonably necessary to
        protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying
        and personally-identifying information.
      </div>

      <div class="col-12 h6 mt-3">- Aggregated Statistics
      </div>
      <div class="col-12">Universal Processing LLC may collect statistics about the behavior of visitors to its website.
        Universal Processing LLC may display this information publicly or provide it to others. However, Universal
        Processing LLC does not disclose your personally-identifying information.
      </div>

      <div class="col-12 h6 mt-3">- Affiliate Disclosure
      </div>
      <div class="col-12">This site uses affiliate links and does earn a commission from certain links. This does not
        affect your purchases or the price you may pay.
      </div>

      <div class="col-12 h6 mt-3">- Cookies
      </div>
      <div class="col-12">To enrich and perfect your online experience, Universal Processing LLC uses "Cookies", similar
        technologies and services provided by others to display personalized content, appropriate advertising and store
        your preferences on your computer.
      </div>
      <div class="col-12">A cookie is a string of information that a website stores on a visitor's computer, and that
        the visitor's browser provides to the website each time the visitor returns. Universal Processing LLC uses
        cookies to help Universal Processing LLC identify and track visitors, their usage of
        http://demo.userveonline.com, and their website access preferences. Universal Processing LLC visitors who do not
        wish to have cookies placed on their computers should set their browsers to refuse cookies before using
        Universal Processing LLC's websites, with the drawback that certain features of Universal Processing LLC's
        websites may not function properly without the aid of cookies.
      </div>
      <div class="col-12">By continuing to navigate our website without changing your cookie settings, you hereby
        acknowledge and agree to Universal Processing LLC's use of cookies.
      </div>

      <div class="col-12 h6 mt-3">- E-commerce
      </div>
      <div class="col-12">Those who engage in transactions with Universal Processing LLC – by purchasing Universal
        Processing LLC's services or products, are asked to provide additional information, including as necessary the
        personal and financial information required to process those transactions. In each case, Universal Processing
        LLC collects such information only insofar as is necessary or appropriate to fulfill the purpose of the
        visitor's interaction with Universal Processing LLC. Universal Processing LLC does not disclose
        personally-identifying information other than as described below. And visitors can always refuse to supply
        personally-identifying information, with the caveat that it may prevent them from engaging in certain
        website-related activities.
      </div>
      <div class="col-12 h6 mt-3">Privacy Policy Changes
      </div>
      <div class="col-12">Although most changes are likely to be minor, Universal Processing LLC may change its Privacy
        Policy from time to time, and in Universal Processing LLC's sole discretion. Universal Processing LLC encourages
        visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site
        after any change in this Privacy Policy will constitute your acceptance of such change.
      </div>

    </div>
  </div>
</div>
