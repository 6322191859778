<ng-container
  ><input
    class="form-check-input"
    type="checkbox"
    [attr.data-value]="modify?.id"
    [id]="'m-' + modify?.id!"
    [checked]="checked"
    (change)="onModifyCheck($event)"
  />
  <label class="form-check-label user-select-none" [for]="'m-' + modify?.id">
    {{ modify!.menu_modify![0]?.name }}
  </label></ng-container
>
