<span
  class="option-ambtn-wrap bg-secondary ms-1 rounded d-inline-flex align-items-center justify-content-center h-100"
>
  <button class="btn btn-secondary p-0" (click)="dec($event)" type="button">
    <div class="d-flex align-items-center justify-content-center">
      <span class="badge bg-secondary p-0">
        <i class="bi bi-dash"></i>
      </span>
    </div>
  </button>

  <span class="badge bg-secondary ms-1 me-1 option-quantity-text">{{
    size
  }}</span>

  <button
    class="btn btn-secondary p-0"
    (click)="inc($event)"
    type="button"
    [disabled]="dis"
  >
    <div class="d-flex align-items-center justify-content-center">
      <span class="badge bg-secondary p-0">
        <i class="bi bi-plus"></i>
      </span>
    </div>
  </button>
</span>
