<div class="modal-header">
  <h5 class="modal-title">Enter a custom tip</h5>
  <button
    type="button"
    class="close btn"
    aria-label="Close"
    (click)="activeModal.dismiss(false)"
  >
    <div aria-hidden="true">&times;</div>
  </button>
</div>
<div class="modal-body fs-6 fw-500">
  <div class="input-group mb-3">
    <span class="input-group-text">$</span>
    <input
      uoTwoDigitDecimalNumber
      type="number"
      step="0.01"
      pattern="^[0-9]*\.[0-9]{2}$"
      maxlength="10"
      class="form-control"
      [(ngModel)]="tipsAmount"
      (focus)="onFocus($event)"
      placeholder="please input amount"
      aria-label="tips_amount"
    />
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.dismiss(false)"
  >
    Close
  </button>
  <!-- [disabled]=" loading" -->
  <button
    type="button"
    class="btn btn-danger"
    [disabled]="loading"
    (click)="updateTips()"
  >
    <div class="user-select-none">Update tips</div>
  </button>
</div>
